<template>
    <div>
        <custom-vue-table class="pl-3 pr-3" title="Customers" :fields="fields" :url="listUrl" :per-page="10" ref="table"
                          :extra-params="{ start_date, status, end_date }">
            <template #filters>
                <div class="btn-group ml-3">
                    <drop-btn text="Filters" class="ml-3" icon="fa fa-filter" size="md" dropdown-width="25r">
                        <div class="p-3">
                            <validated-vue-select name="Status" :options="statusOptions"
                                                  label="Status"
                                                  v-model="status"/>
                            <validated-input type="date" label="Start Date" name="Start Date"
                                             v-model="start_date"/>
                            <validated-input type="date" label="End Date" name="End Date"
                                             v-model="end_date"/>
                        </div>
                    </drop-btn>
                    <btn color="primary" size="md" @click="$refs.addModal.show()" text="Add"/>
                </div>
            </template>
            <template #actions="{rowData}">
                <div class="btn-group-tight">
                    <btn color="primary" v-if="rowData.is_active" class="btn-basic-b" size="xs" @click="suspendUser(rowData)" text="Suspend"/>
                    <btn color="primary" v-else class="btn-basic-b" size="xs" @click="suspendUser(rowData)" text="Retrieve"/>
                    <btn color="primary" class="btn-basic-b" size="xs" @click="viewDetails(rowData)" text="View"/>
                </div>
            </template>
        </custom-vue-table>

        <modal no-close-on-backdrop title="Add Customer" ref="addModal" width="45r">
            <b-form :save-url="addUrl" @success="formSuccess" v-slot="{model, loading}">
                <div class="row">
                    <div class="col">
                        <validated-input label="Name" name="Name" v-model="model.name" :rules="{required:true}"
                                         :disabled="loading"/>
                    </div>
                    <div class="col">
                        <validated-input label="Email" name="Email" type="email" v-model="model.email"
                                         :rules="{required:true}"
                                         :disabled="loading"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <validated-input label="Mobile Number" name="Mobile Number" v-model="model.mobile_number"
                                         :rules="{required:true}" type="number"
                                         :disabled="loading"/>
                    </div>
                    <div class="col">
                        <validated-input type="date" label="Date of Birth" name="Dob"
                                         v-model="model.dob" :rules="{required:true}"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <validated-vue-select name="Gender" :options="genderOptions" :rules="{required:true}"
                                              label="Gender" v-model="model.gender"/>
                    </div>
                    <div class="col">
                        <validated-vue-select name="Channel" :options="channelOptions" :rules="{required:true}"
                                              label="Channel" v-model="model.channel"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <validated-ajax-vue-select name="Group" :url="groupOptions" :rules="{required:true}"
                                                   label="Group" v-model="model.group"/>
                    </div>
                    <div class="col">
                        <validated-input type="password" label="Password" name="Password" v-model="model.password"
                                         :rules="{required:true}" :disabled="loading"/>
                    </div>
                </div>
                <btn icon="" text="Save" size="sm" :loading="loading" loading-text="Saving..."/>
            </b-form>
        </modal>
    </div>
</template>

<script>
import urls from '../../../../data/urls';
import axios from 'secure-axios';

export default {
    name : 'ListingComponent',
    data () {
        return {
            start_date     : '',
            end_date       : '',
            status         : '',
            listUrl        : urls.salesAdmin.customers.list,
            addUrl         : urls.salesAdmin.customers.add,
            channelOptions : [
                { value : 'Mobile Application', label : 'Mobile Application' },
                { value : 'Website', label : 'Website' }
            ],
            groupOptions  : urls.salesAdmin.customers.groupOptions,
            statusOptions : [
                { value : 'True', label : 'Active' },
                { value : 'False', label : 'Suspend' }
            ],
            genderOptions : [
                { value : 'M', label : 'male' },
                { value : 'F', label : 'female' },
                { value : 'O', label : 'Other' }
            ],
            fields : [
                {
                    name      : 'id',
                    sortField : 'id',
                    title     : 'ID'
                },
                {
                    name      : 'name',
                    sortField : 'name',
                    title     : 'Name'
                },
                {
                    name      : 'email',
                    sortField : 'email',
                    title     : 'Email'
                },
                {
                    name      : 'group',
                    sortField : 'group',
                    title     : 'Group'
                },
                {
                    name      : 'channel',
                    sortField : 'channel',
                    title     : 'Channel'
                },
                {
                    name      : 'mobile_number',
                    sortField : 'mobile_number',
                    title     : 'Phone'
                },
                {
                    name      : 'status',
                    sortField : 'status',
                    title     : 'Status'
                },
                {
                    name  : '__slot:actions',
                    title : 'Actions'
                }

            ]
        };
    },
    methods : {
        async suspendUser (item) {
            const that = this;
            that.loading = true;
            const response = await axios.form(urls.salesAdmin.customers.suspend, { user : item.id });
            const json = response.data;
            if (json.error === false) {
                that.$notify('Updated status successfully', 'Success', {
                    type : 'success'
                });
                that.formSuccess();
                that.loading = false;
            } else {
                that.$notify('Sorry, Please try again later', 'Failed', {
                    type : 'danger'
                });
                that.loading = false;
            }
        },
        viewDetails (rowData) {
            this.$router.push('/sales/customer/' + rowData.id + '/details/');
        },
        formSuccess () {
            const refs = this.$refs;
            refs.addModal.close();
            refs.table.refreshTable();
        }

    }
};
</script>
